import { graphql, useStaticQuery, Link } from "gatsby"
import React, { ReactNode } from "react"
import "reflect-metadata"
import Layout from "../layout"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { INLINES, BLOCKS } from "@contentful/rich-text-types"
import styled from "styled-components"
import { Column, Container, Row, FlexProps } from "../styles/grids"

interface TeachingPageProps {
    children: ReactNode
}

// TODO: Typify
interface TeachingData {
    contentfulTeaching: {
        teachingBlurb: {
            raw: any
        }
    }
}

const SideColumns = styled.div`
    flex: 1;
    @media only screen and (max-width: 767px) {
        flex: 0;
    }
`

const StyledLink = styled(Link)`
    text-decoration: none;
    color: #f3867f;
    &:hover {
        text-decoration: underline;
        cursor: pointer;
    }

    h1 {
        font-family: Inconsolata;
        font-weight: 200;
        font-size: 30px;
        margin: 5px;
    }
`

const TeachingPage: React.FC<TeachingPageProps> = () => {
    const { contentfulTeaching }: TeachingData = useStaticQuery(graphql`
        query {
            contentfulTeaching {
                teachingBlurb {
                    raw
                }
            }
        }
    `)

    return (
        <Layout>
            <Container>
                <Row>
                    <SideColumns />
                    <Column flex={3} marginBottom={"10vw"}>
                        {documentToReactComponents(
                            JSON.parse(contentfulTeaching.teachingBlurb.raw),
                            teaching_option()
                        )}
                    </Column>
                    <SideColumns />
                </Row>
            </Container>
        </Layout>
    )
}

const teaching_option = () => {
    const ColumnProps = {
        // border: '1px solid #F3867F',
        color: "#F3867F",
        flex: 1,
    }

    const option_node = {
        renderNode: {
            [BLOCKS.EMBEDDED_ASSET]: (node: any) => {
                return (
                    <img
                        title={node.data.target.fields.title}
                        src={`http:${node.data.target.fields.file["en-US"].url}`}
                        alt={node.data.target.fields.title}
                    />
                )
            },
            [BLOCKS.PARAGRAPH]: (node: any, children: any) => {
                return (
                    <Column {...ColumnProps}>
                        <p style={{ textAlign: "center", fontSize: "24px" }}>
                            {children}
                        </p>
                    </Column>
                )
            },
            [INLINES.HYPERLINK]: (node: any, children: any) => {
                return (
                    <StyledLink to={node.content[0].value}>
                        <u>{children}</u>
                    </StyledLink>
                )
            },
        },
    }
    return option_node
}

export default TeachingPage
